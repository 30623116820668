import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Popup, Table, Visibility} from 'semantic-ui-react';
import { dateToString } from '../../utils/dateTimeFormater';
import {
    clearReport,
    columnsSelector,
    exportProgressSelector,
    getReportRequest,
    progressSelector,
    reportExportToExcelRequest, reportPageSelector,
    reportSelector, reportTotalCountSelector,
} from '../../ducks/reports';
import {
    ADDITIONAL_POINTS_COST_REPORT_TYPE,
} from '../../constants/reportType';
import { useTranslation } from 'react-i18next';
import Block from '../../components/CardLayout/components/block';
import CellValue from '../../components/ColumnsValue';
import FacetField from '../../components/FilterComponents';
import Icon from '../../components/CustomIcon';
import ReportFilters from "./reportFilters";
import Search from '../../components/Search';

const AdditionalPointsCostReport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let [params, setParams] = useState({
        startDate: dateToString(),
        endDate: dateToString(),
        directionType: {
            value: 'noDirection', 
            name: 'noDirection'
        },
        dateType: {
            value: 'reportPeriodPlannedShippingDateType',
            name: 'reportPeriodPlannedShippingDateType'
        },
        shippingNumbers: [],
        orderNumbers: [],
        to: [],
        from: []
    });

    let [filter, setFilter] = useState({});
    let [sort, setSort] = useState({});

    let [isFirstRequest, setIsFirstRequest] = useState(true);

    let columns = useSelector(columnsSelector);
    let report = useSelector(reportSelector);
    let totalCount = useSelector(reportTotalCountSelector);
    let page = useSelector(reportPageSelector);
    let reportProgress = useSelector(progressSelector);
    let exportProgress = useSelector(exportProgressSelector);
    
    let [searchQuery, setSearchQuery] = useState('');
        
    useEffect(() => {
        if (isFirstRequest) {
            dispatch(clearReport());
            setIsFirstRequest(false);
        }
        else {
            getReport(0);
        }
    }, [filter, sort, searchQuery]);
    
    const mapData = (page) => {
        return {
            type: ADDITIONAL_POINTS_COST_REPORT_TYPE,
            params: {
                ...params,
                filter,
                searchQuery,
                sort,
                page: page
            },
        };
    };
    
    const handleSetSearchQuery = (e, { value }) => {
        setSearchQuery(value);
    };

    const getReport = (page) => {
        if (!reportProgress) {
            dispatch(getReportRequest(mapData(page)));
        }
        // if (!exportProgress) {
        //     dispatch(reportExportToExcelRequest(mapData(page)));
        // }
    };

    const handleChangeFilter = (e, { name, value }) => {
        setFilter(filter => {
            let newFilter = { ...filter };

            if (value) {
                newFilter = {
                    ...filter,
                    [name]: value,
                };
            } else {
                delete newFilter[name];
            }

            return newFilter;
        });
    };

    const clearFilter = () => {
        setFilter({});
    };

    const exportExcel = () => {
        if (!exportProgress) {
            dispatch(reportExportToExcelRequest(mapData(page)));
        }
    };

    const generateReport = () => {
        setFilter({});
        setSort({});
        getReport(0);
    };

    const nextPage = () => {
        if (report.length < totalCount && !reportProgress) {
            getReport(page + 1);
        }
    }

    const item = {
        menuItem: 'Additional points cost report',
        render: () => (
            <div style={{ position: 'relative' }}>
                <Table compact={true} size={"small"}>
                    <Table.Header>
                        <Table.Row>
                            {columns.map((column, i) => (
                                <Table.HeaderCell>
                                    <FacetField
                                        key={'facet' + column.name}
                                        index={i}
                                        gridName={"reports/"+ADDITIONAL_POINTS_COST_REPORT_TYPE}
                                        name={column.name}
                                        displayNameKey={column.displayNameKey}
                                        sort={sort && sort.name === column.name ? sort.desc : null}
                                        setSort={setSort}
                                        type={column.type}
                                        value={filter[column.name]}
                                        filters={filter}
                                        setFilter={handleChangeFilter}
                                        source={column.source}
                                    />
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {report.map((row, indexRow) => (
                            <Table.Row key={row.id}>
                                {columns.map((column, indexColumn) => (
                                    <Table.Cell key={`${row.id}_${column.name}`}>
                                        <CellValue
                                            {...column}
                                            indexRow={indexRow}
                                            indexColumn={indexColumn}
                                            value={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].value
                                                    : row[column.name]
                                            }
                                            valueText={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].name
                                                    : null
                                            }
                                            valueTooltip={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].tooltip
                                                    : null
                                            }
                                            rowId={row.id}
                                            t={t}
                                        />
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Visibility
                    once={false}
                    onTopVisible={nextPage}
                />
            </div>
        ),
        actions: () => [
            <Popup
                content={t('reset_filters')}
                position="bottom right"
                trigger={
                    <Button
                        icon
                        className={`clear-filter-btn`}
                        onClick={clearFilter}
                        disabled={!Object.keys(filter).length}
                    >
                        <Icon name="clear-filter" />
                    </Button>
                }
            />,
            <Search isAuto
                value={searchQuery}
                className="search-input"
                onChange={handleSetSearchQuery}
            />,
        ],
    };

    return (
        <div className="container" style={{ overflowY: 'auto', height: '99vh', maxHeight: '99vh' }}>
            <div className="report">
                <ReportFilters
                    reportType={ADDITIONAL_POINTS_COST_REPORT_TYPE}
                    params={params}
                    setParams={setParams}
                    onGenerateReport={generateReport}
                    onExportExcel={exportExcel}
                />
                <div className="report_table-top-padding-450">
                    <Block item={item} actions={item.actions} loading={reportProgress} isFullScreen />
                </div>
            </div>
        </div>
    );
};

export default AdditionalPointsCostReport;
